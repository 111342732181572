
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { IconRotate } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmEmptyState',
  props: {
    icon: {
      type: String,
    },
    iconRotate: {
      type: Number as PropType<IconRotate>,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    beta: {
      type: Boolean,
    },
    white: {
      type: Boolean,
    },
  },
})
