
import { defineComponent, ref } from 'vue'
import WorkspaceUsersModalInvite from '@/components/pages/admin/workspace/users/WorkspaceUsersModalInvite.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    WorkspaceUsersModalInvite,
  },
  setup() {
    const disableInviteLink = ref(false)
    const inviteButtonText = ref('Save changes')

    const onDisableInviteLink = () => {
      disableInviteLink.value = true
    }
    const onGeneratedInvite = () => {
      disableInviteLink.value = false
      inviteButtonText.value = 'Done'
    }

    return {
      disableInviteLink,
      inviteButtonText,
      onDisableInviteLink,
      onGeneratedInvite,
    }
  },
})
