import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_workspace_users_modal_invite = _resolveComponent("workspace-users-modal-invite")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "workspaceUsersInvite",
    "modal-title": "Invite users via link",
    size: "xLarge",
    "no-footer": _ctx.disableInviteLink
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_workspace_users_modal_invite, {
        onDisableInviteLink: _ctx.onDisableInviteLink,
        onGeneratedInvite: _ctx.onGeneratedInvite
      }, null, 8, ["onDisableInviteLink", "onGeneratedInvite"])
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.inviteButtonText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["no-footer"]))
}