
import { defineComponent, ref } from 'vue'
import { copyToClipboard } from '@/services/utils'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { tagsOptions, roleOptions, teamsOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    TmModal,
    TmTooltip,
    TmEmptyState,
  },
  emits: ['disableInviteLink', 'generatedInvite'],
  setup(props, context) {
    const emptyShare = ref(false)
    const disableModal = ref()
    const expiresOptions = ref(['Keep current expiration date', 'In 1 day', 'In 7 days', 'In 14 days', 'In 30 days'])
    const inviteLink = ref({
      expires: 'Keep current expiration date',
      role: 'Administrator',
      teams: [],
    })
    const inviteLinkText = ref('https://join.textmagic.com/4r5f7f7h')
    const copied = ref(false)
    const copyFunc = (text: string) => {
      copied.value = true
      copyToClipboard(text)
    }
    const disableLink = () => {
      emptyShare.value = true
      disableModal.value.hideModal()
      context.emit('disableInviteLink')
    }

    const tagsList = ref(tagsOptions)

    const onGenerateInvite = () => {
      inviteLink.value.expires = 'In 14 days'
      emptyShare.value = false
      context.emit('generatedInvite')
    }

    return {
      emptyShare,
      roleOptions,
      disableModal,
      teamsOptions,
      expiresOptions,
      inviteLink,
      tagsList,
      copied,
      inviteLinkText,
      copyFunc,
      onGenerateInvite,
      disableLink,
    }
  },
})
