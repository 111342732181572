import type { StatusType } from '@/definitions/shared/types'
import type {
  ActivityStatusName,
  ChatStatusName,
  ContactStatusName,
  member,
  TicketsStatusName
} from '@/definitions/_general/_types/optionsList'

export const contactPhoneType = ['Primary', 'Auto', 'Mobile', 'Landline']
export const contactEmailType = ['Primary', 'General', 'Work', 'Personal', 'Other']

export const roleOptions = [
  'Administrator',
  'Agent',
  'Accountant',
  'Supervisor',
]

export const chatStatusOptions: StatusType<ChatStatusName>[] = [
  { name: 'Open', color: 'orange', outline: true },
  { name: 'Solved', color: 'green', outline: true },
]

export const chatTagsFilterOptions = [
  'Collection', 'Sales', 'Summer', 'Winter',
]
export const tagsOptions = [
  'account', 'issue', 'tag1', 'tag2', 'tag3', 'tag4', 'tag5', 'tag6', 'tag7', 'tag8', 'tag9',
]
export const teamsOptions = [
  {
    image: '',
    color: 'purple',
    name: 'General',
    users: 13,
  },
  {
    image: '',
    color: 'blue',
    name: 'USA Marketing',
    users: 22,
  },
  {
    image: '',
    color: 'orange',
    name: 'UK Marketing',
    users: 47,
  },
  {
    image: '',
    color: 'red',
    name: 'Australia Marketing',
    users: 15,
  },
]

export const assigneeOptions: member[] = [
  { fullName: 'Arlene McCoy', email: 'grolschie@mac.com', avatarColor: 'red' },
  { fullName: 'Sava Nguyen', email: 'amaranth@outlook.com', avatarColor: 'green' },
  { fullName: 'Darlene Miles', email: 'hwestiii@mac.com', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg', avatarColor: 'orange' },
  { fullName: 'Esther Pena', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', avatarColor: 'blue' },
  { fullName: 'Flores Juanita', email: 'hwestiii@mac.com', avatar: 'https://randomuser.me/api/portraits/women/33.jpg', avatarColor: 'mint' },
  { fullName: 'Nguyen Shane', avatar: 'https://randomuser.me/api/portraits/men/14.jpg', avatarColor: 'red' },
  { fullName: 'Helen Arthur', email: 'hwestiii@mac.com', avatar: 'https://randomuser.me/api/portraits/women/43.jpg', avatarColor: 'green' },
  { fullName: 'Wade Warren', avatar: 'https://randomuser.me/api/portraits/men/12.jpg', avatarColor: 'blue' },
]

export const createdByOptions: member[] = [
  { fullName: 'System', avatarColor: 'gray', avatarIcon: 'gray' },
  ...assigneeOptions,
]

export const subAccountRoles: StatusType[] = [
  {
    color: 'blue',
    outline: true,
    name: 'admin',
  },
  {
    color: 'gray',
    outline: true,
    name: 'user',
  },
]

export const subAccountStatuses: StatusType[] = [
  {
    color: 'orange',
    outline: true,
    name: 'never logged in',
  },
  {
    color: 'orange',
    outline: true,
    name: 'invited',
  },
  {
    color: 'green',
    outline: true,
    name: 'active',
  },
]

export const contactListsOptions = [
  { label: 'New trials 13-Jan-22', hint: '135', icon: 'people' },
  { label: 'VIP customers Jan 2022', hint: '735', icon: 'people' },
  { label: 'James Owen', hint: '35', icon: 'people' },
  { label: 'Newsletter 2022', hint: '135', icon: 'people' },
  { label: 'Test list 2022', hint: '595', icon: 'people' },
  { label: 'Bug test list 13-Jan', hint: '115', icon: 'people' },
  { label: 'Best customers VIP offer', hint: '85', icon: 'people' },
  { label: 'Key account', hint: '35', icon: 'people' },
  { label: 'Important', hint: '47', icon: 'people' },
  { label: 'VIP Leads', hint: '5', icon: 'people' },
]

export const contactSegmentsOptions = [
  { label: 'USA contacts', hint: '78', icon: 'pie_chart' },
  { label: 'UE contacts', hint: '78', icon: 'pie_chart' },
  { label: 'Recent', hint: '78', icon: 'pie_chart' },
  { label: 'Imported', hint: '78', icon: 'pie_chart' },
  { label: 'Perspective', hint: '78', icon: 'pie_chart' },
  { label: 'Autumn sale', hint: '24', icon: 'pie_chart' },
  { label: 'Summer', hint: '57', icon: 'pie_chart' },
  { label: 'A Guide', hint: '67', icon: 'pie_chart' },
]

export const categoryOptions = [
  'All categories',
  'General',
  'Category 1',
  'Category 2',
  'Category 3',
]

export const countryCodes = [
  {
    value: '1',
    label: 'United States',
    country: {
      name: 'United States',
      id: 'us',
      code: '(855)',
    },
  },
  {
    value: '1250',
    label: 'Canada',
    country: {
      name: 'Canada',
      id: 'ca',
      code: '+1 (250)',
    },
  },
  {
    value: '1',
    label: 'United States',
    country: {
      name: 'United States',
      id: 'us',
      code: '+1',
    },
  },
  {
    value: '44',
    label: 'United Kingdom',
    country: {
      name: 'United Kingdom',
      id: 'uk',
      code: '+44',
    },
  },
  {
    value: '47',
    label: 'Norway',
    country: {
      name: 'Norway',
      id: 'no',
      code: '+47',
    },
  },
  {
    value: '33',
    label: 'France',
    country: {
      name: 'France',
      id: 'fr',
      code: '+33',
    },
  },
  {
    value: '372',
    label: 'Estonia',
    country: {
      name: 'Estonia',
      id: 'ee',
      code: '+372',
    },
  },
  {
    value: '61',
    label: 'Australia',
    country: {
      name: 'Australia',
      id: 'au',
      code: '+61',
    },
  },
  {
    value: '49',
    label: 'Germany',
    country: {
      name: 'Germany',
      id: 'de',
      code: '+49',
    },
  },
]

export const recipientOptions = [
  { type: 'person', label: 'Dianne Russell', hint: '+447568301636', avatarColor: 'blue' },
  { type: 'list', label: 'Marketing', hint: '18 recipients', shortHint: 18 },
  { type: 'list', label: 'April subscribers', hint: '355 recipients', shortHint: 355 },
  { type: 'person', label: 'Mike Richards', hint: '(307) 555-0133', avatarColor: 'orange' },
  { type: 'person', label: 'Mike Robertson', hint: '(406) 555-0120', avatarColor: 'purple' },
  { type: 'person', label: 'Mike Nguyen', hint: '(270) 555-0117', avatarColor: 'green' },
  { type: 'phone', label: '(684) 555-0102', hint: 'United States' },
  { type: 'list', label: 'Mike list', hint: '153 recipients', shortHint: 153 },
]

export const templatesCategoryOptions = ['Marketing', 'Support', 'Sales', 'Not sent']

export const additionalSettingsFromOptions = [
  { label: 'Default sender settings (recommended)' },
  { subheader: 'Textmagic numbers', disabled: true },
  { label: '+1 855-543-5326 (USA Support)' },
  { label: '+33 06 86 57 90 14 (France)' },
  { label: '+44 725-552-9248 (United Kingdom)' },
  { label: '+44 735-557-7146 (United Kingdom)' },
  { subheader: 'Sender IDs', disabled: true },
  { label: 'Textmag106' },
  { label: 'Devtest' },
  { label: 'Textmagic' },
  { subheader: 'Your mobile phone', disabled: true },
  { label: '+1 (144) 879-3610 ' },
]

export const senderSettingsOptions = [
  { label: 'Default sender settings (recommended)' },
  { subheader: 'Twilio numbers (BYOC)', disabled: true },
  { id: 'us', name: '(345) 434 7654', counter: 'USA Support' },
  { id: 'uk', name: '+44 7355 577146' },
  { subheader: 'Textmagic numbers', disabled: true },
  { id: 'us', name: '+1 855-543-5326', counter: 'USA Support' },
  { id: 'fr', name: '+33 06 8657 9014', counter: 'Another label' },
  { id: 'uk', name: '+44 725-552-9248', counter: 'United Kingdom' },
  { id: 'uk', name: '+44 735-557-7146', counter: 'United Kingdom' },
  { subheader: 'Sender IDs', disabled: true },
  { label: '-  Sender ID is not supported in this country - ', disabled: true },
  { subheader: 'Your mobile phone', disabled: true },
  { id: 'us', name: '+1 (144) 879-3610 ' },
]

export const chatChannelsFilterOptions = [
  { name: 'Default sender settings', counter: 'recommended' },
  { subheader: 'Twilio BYOC', disabled: true },
  { id: 'us', name: '(205) 555-0100', counter: 'USA Support' },
  { id: 'uk', name: '+44 7855 504126' },
  { id: 'fr', name: '+33 702 555 012' },
  { subheader: 'Textmagic numbers', disabled: true },
  { id: 'uk', name: '+44 7585 550112 ' },
  { id: 'fr', name: '+33 201 555 012', counter: 'Sales' },
  { id: 'us', name: '(603) 555-0123' },
]

export const reportsNumbersOptions = chatChannelsFilterOptions.slice(1)

export const reportsChannelsOptions = [
  { subheader: 'WhatsApp', disabled: true },
  { name: 'Acme Sales' },
  { name: 'Acme Support' },
  { name: 'Acme Finance' },
  { subheader: 'WhatsApp', disabled: true },
  { name: 'Acme US' },
  { name: 'Acme Australia' },
  { subheader: 'Instagram', disabled: true },
  { name: 'Acme team events' },
]

export const widgetNumberOptions = [
  { id: 'us', name: '+1 (823) 861-8832', counter: 'USA Support' },
  { id: 'fr', name: '+66-2-2134567', counter: 'FR Team' },
  { id: 'de', name: '+44 23-23-3567', counter: 'DE Team' },
  { id: 'dk', name: '+44 23-23-3567', counter: 'DK Team' },
  { id: 'uk', name: '+44 765 5554 290', counter: 'UK Support' },
  { label: 'None' },
]

export const reportingCategories = [
  {
    label: 'All accounts (38)',
    noAvatar: true,
  },
  {
    label: 'Andrey Platonov - andrey.p@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Roman Romanov - roman.r@textmagic.biz',
    avatarColor: 'orange',
  },
  {
    label: 'Dmitriy Efremov - dmitriy.efremov@textmagic.biz',
    avatarColor: 'green',
  },
  {
    label: 'Alexander Pysarchuk - alexander.p@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Alexander Ilchuk - olexandr.ilchuk@gmail.com',
    avatarColor: 'blue',
  },
  {
    label: 'A R - arogonov+1@gmail.com',
    avatarColor: 'mint',
  },
  {
    label: 'Vitalii Potapov - vitalii.potapov@textmagic.biz',
    avatarColor: 'orange',
  },
  {
    label: 'Ihar Siatkouski - igorsiatkovsky@gmail.com',
    avatarColor: 'green',
  },
  {
    label: 'Mikhail Metryuk - mikhail.metryuk+prod@textmagic.biz',
    avatarColor: 'mint',
  },
  {
    label: 'Pavel Tsyganov - pavel.tsyganov@textmagic.biz',
    avatarColor: 'orange',
  },
  {
    label: 'Pavel Chervov - pavel.chervov@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Mikhail 123 - mikhail+asdqwe123@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Mikhail Textmagic123 - mikhail+testpart@textmagic.biz',
    avatarColor: 'mint',
  },
  {
    label: 'Alexey Rozhkov - a.rozhkov@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Andrey Platonov - a.platonov@textmagic.biz',
    avatarColor: 'green',
  },
  {
    label: 'Artem Saveliev - dev.saveliev@gmail.com',
    avatarColor: 'orange',
  },
  {
    label: 'Mykhail Rozbytskyi - mishanyarozbitskiy@gmail.com',
    avatarColor: 'orange',
  },
  {
    label: 'Adela Test - adela.ionita@textmagic.biz',
    avatarColor: 'green',
  },
  {
    label: 'Dragos Raducanu - dragos@textmagic.biz',
    avatarColor: 'green',
  },
  {
    label: 'Yuriy B - ybperpetio@gmail.com',
    avatarColor: 'orange',
  },
  {
    label: 'Dmitrii Erashkin - dmitry.yerashkin@textmagic.biz',
    avatarColor: 'mint',
  },
  {
    label: 'Edward Tark - edward.tark+ios@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Denis Konchekov - denis.konchekov@textmagic.biz',
    avatarColor: 'mint',
  },
  {
    label: 'Denis Test - rasprc+testas@gmail.com',
    avatarColor: 'mint',
  },
  {
    label: 'Training Training - edward.tark+testtraining@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Igor Pavlov - igor.pavlov1987@gmail.com',
    avatarColor: 'green',
  },
  {
    label: 'Mikhail Kuzin - mikhail+mm@textmagic.biz',
    avatarColor: 'mint',
  },
  {
    label: 'AlexeyTest RozhkovTest - a.rozhkov+1@textmagic.biz',
    avatarColor: 'orange',
  },
  {
    label: 'Oleg Maiko - olegmaiko59@gmail.com',
    avatarColor: 'mint',
  },
  {
    label: 'Mikhail Kuzin 106 - mikhail+106@textmagic.biz',
    avatarColor: 'mint',
  },
  {
    label: 'Roman Leukhin - leukhinromanigorevich@gmail.com',
    avatarColor: 'blue',
  },
  {
    label: 'Denis Test - denis+546456@textmagic.biz',
    avatarColor: 'blue',
  },
  {
    label: 'Max BurpSuite - maxim+burpsuite@textmagic.biz',
    avatarColor: 'orange',
  },
  {
    label: 'Pavel Son - pavel.s@textmagic.biz',
    avatarColor: 'green',
  },
  {
    label: 'Test Test - edward.tark+pass1@textmagic.biz',
    avatarColor: 'green',
  },
  {
    label: 'Denis Senchikhin - rasprc+12@gmail.com',
    avatarColor: 'blue',
  },
  {
    label: 'Dmitry Khapenkov - dmitriy.khapenkov@textmagic.biz',
    avatarColor: 'orange',
  },
  {
    label: 'Edward Tark - edward.tark@textmagic.biz',
    avatarColor: 'green',
  },
]

export const contactsStatusOptions: StatusType<ContactStatusName>[] = [
  { name: 'customer', color: 'green', outline: true },
  { name: 'lead', color: 'orange', outline: true },
  { name: 'student', color: 'violet', outline: true },
  { name: 'employee', color: 'blue', outline: true },
]

export const ticketsStatusOptions: StatusType<TicketsStatusName>[] = [
  { name: 'new', color: 'red', outline: false, isDefault: true },
  { name: 'open', color: 'orange', outline: true },
  { name: 'pending', color: 'blue', outline: true },
  { name: 'need attention', color: 'red', outline: true },
  { name: 'solved', color: 'green', outline: true, isDefault: true },
  { name: 'closed', color: 'gray', outline: true, isDefault: true },
  { name: 'draft', color: 'gray', outline: true, isDefault: true },
]

export const priorityOptions = ['400', '300', '200', '100']
export const countryOptions = [
  {
    name: 'United Kingdom',
    id: 'uk',
  },
  {
    name: 'Germany',
    id: 'de',
  },
  {
    name: 'Spain',
    id: 'es',
  },
  {
    name: 'France',
    id: 'fr',
  },
]

export const contactsFieldsList = [
  {
    icon: 'tmi-form',
    label: 'Country',
  },
  {
    icon: 'tmi-form',
    label: 'Lead status',
  },
  {
    icon: 'tmi-form-textbox',
    label: 'Segments',
  },
  {
    icon: 'tmi-form',
    label: 'Source',
  },
  {
    icon: 'tmi-form',
    label: 'Timezone',
  },
]

export const organizationsFieldsList = [
  {
    icon: 'tmi-123',
    label: 'Contacts',
  },
  {
    icon: 'tmi-123',
    label: 'Total interactions',
  },
]

export const organizationsOptions = [
  {
    image: require('@/assets/images/google.svg'),
    name: 'Google',
    domain: 'google.com',
  },
  {
    image: require('@/assets/images/lastpass.svg'),
    name: 'LastPass',
    domain: 'lastpass.comlastpass.comlastpass.comlastpass.com',
  },
  {
    image: require('@/assets/images/zoho.png'),
    name: 'Zoho',
  },
  {
    image: '',
    name: 'Sunflower Limited',
    avatarColor: 'green',
  },
]

export const organizationTypesOptions = [
  {
    name: 'partner',
    outline: true,
    color: 'blue',
  },
  {
    name: 'vendor',
    outline: true,
    color: 'orange',
  },
  {
    name: 'customer',
    outline: true,
    color: 'green',
  },
]

export const activityStatusOptions: StatusType<ActivityStatusName>[] = [
  { name: 'open', color: 'orange', outline: true },
  { name: 'scheduled', color: 'green', outline: false },
  { name: 'solved', color: 'green', outline: true },
  { name: 'pending', color: 'blue', outline: true },
]
export const activityOptions = [
  'Calls',
  'Mobile chats',
  'Email threads',
  'Whatsapp chats',
  'Facebook messenger chats',
  'Notes',
]

export const organizationSizeOptions = [
  '1-9 employees',
  '10-49 employees',
  '50-199 employees',
  '200-499 employees',
  '500-999 employees',
  '1,000-5,000 employees',
]
export const industryOptions = [
  'Agriculture',
  'Art, Design, and Fashion',
  'Automotive',
  'Ecommerce',
  'Education and Training',
  'Energy and Utilities',
  'Engineering',
  'Entertainment and Gaming',
  'Financial and Insurance services',
  'Food and Beverage',
  'Government and Non-Profits',
  'Healthcare',
  'IT services',
  'Manufacturing',
  'Marketing and Advertising',
  'Media and Communication',
  'Personal care services',
  'Professional services (Law, Consulting, Accounting, HR, etc)',
  'Real estate and Construction',
  'Retail',
  'Software (including SaaS)',
  'Sport and Fitness',
  'Telecommunication',
  'Tourism',
  'Transport and Logistics',
  'Other',
]

export const boardOptions = [
  {
    name: 'Personal',
    emoji: 'smiling',
  },
  {
    name: 'Marketing',
    emoji: 'rocket',
  },
  {
    name: 'Support',
    emoji: 'headset',
  },
  {
    name: 'Sales',
    emoji: 'cash',
  },
  {
    name: 'Dev',
    emoji: 'tools',
  },
  {
    name: 'Management',
    emoji: 'mac-working',
  },
  {
    name: 'Design',
    emoji: 'flower',
  },
]

export const pipelinesOptions = [
  {
    name: 'Sales US',
    emoji: 'money',
  },
  {
    name: 'Sales UK',
    emoji: 'uk',
  },
  {
    name: 'Real estate US',
    emoji: 'us',
  },
  {
    name: 'Real estate UK',
    emoji: 'uk',
  },
]

export const campaignStatusOptions: StatusType[] = [
  { name: 'scheduled', color: 'green', outline: true },
  { name: 'paused', color: 'orange', outline: true },
]

export const campaignSentMessagesOptions: StatusType[] = [
  { name: 'delivered', color: 'green', outline: true },
  { name: 'rejected', color: 'orange', outline: true },
  { name: 'failed', color: 'red', outline: true },
]
